/*======================
   01. Google fonts
========================*/

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100&display=swap');

@import "slick-carousel/slick/slick.css";

@import "slick-carousel/slick/slick-theme.css";

/*======================
   02. Basic css
========================*/

html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
strong,
i,
ol,
ul,
li,
form,
label,
footer,
header,
menu,
nav,
section {
    margin: 0;
    padding: 0;
}

html {
    scroll-behavior: smooth;
}

body.ido {
    line-height: 1.35;
    font-family: 'Poppins', sans-serif;
    background-color: #fff;
    color: #000;
    font-size: 17px;
    font-weight: 400;
}

body.ido::-webkit-scrollbar {
    border-radius: 0;
    background-color: black;
    width: 15px;
}

body.ido::-webkit-scrollbar-thumb {
    background-color: #06523e;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
}

ol,
ul {
    list-style: none;
}

a:hover {
    text-decoration: none;
}

a:focus,
button:focus,
input:focus,
textarea:focus,
select:focus {
    outline: none;
}

.button {
    padding: 8px 12px;
    background: -o-linear-gradient(338.93deg, #005651 -0.98%, #00c673 100.81%);
    background: linear-gradient(111.07deg, #005651 -0.98%, #00c673 100.81%);
    border-radius: 6px;
    font-size: 16px;
    color: #fff;
    -webkit-transition: 0.1s;
    -o-transition: 0.1s;
    transition: 0.1s;
    display: inline-block;
    border: none;
    white-space: nowrap;
}

.button:hover {
    background: -o-linear-gradient(338.93deg, #00c673 -0.98%, #005651 100.81%);
    background: linear-gradient(111.07deg, #00c673 -0.98%, #005651 100.81%);
    color: #fff;
    -webkit-transition: 0.1s;
    -o-transition: 0.1s;
    transition: 0.1s;
}

/*======================
  Header style
========================*/

header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1022; /* make sure it's UNDERNEATH the modals! */
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid rgba(111, 111, 147, 0.8);
    background-color: transparent;
    transition: background-color 250ms ease-in-out;
}

header.nav-active {
    background-color: black;
}

header .menu li {
    display: inline-block;
}

header .logo-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

header .logo-box a {
    display: flex;
    align-items: center;
}

header .logo-box img {
    width: 50px;
    height: 50px;
}

header .logo-box .brand {
    color: white;
    font-size: 23px;
    font-weight: 600;
    margin-left: 1rem;
    position: relative;
    top: 1px;
    text-transform: lowercase;
}

.mobile-menu-wrapper {
    display: none;
}

header .menu li a,
.mobile-menu-wrapper ul li a {
    font-family: Poppins;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #fff;
    margin-right: 25px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

header .menu li a.nav-link,
.mobile-menu-wrapper ul li a {
    padding: 0;
}

.mobile-menu-wrapper ul li a {
    margin-right: 0;
}

header .menu li a:hover,
.mobile-menu-wrapper ul li a:hover {
    color: #018f62;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

header .menu li:last-child a {
    margin-right: 0;
}

.header-btn-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-end;
}

.header-btn-box .btn-2 {
    color: #fff;
    margin-left: 10px;
    padding: 1px;
}

.header-btn-box .btn-2 span {
    background-color: #000;
    padding: 8px 12px;
    border-radius: 6px;
    display: inline-block;
}

/*======================
  Hamburger-menu style
========================*/

.hamburger-menu {
    cursor: pointer;
    display: none;
    z-index: 999;
}

.hamburger-menu span {
    background: #fff;
    width: 30px;
    height: 2px;
    display: block;
    margin: 6px 0;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.hamburger-menu:hover .line-top {
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
}

.hamburger-menu:hover .line-bottom {
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
}

.hamburger-menu.active .line-top {
    -webkit-transform: translateY(4px) rotate(135deg);
    -ms-transform: translateY(4px) rotate(135deg);
    transform: translateY(4px) rotate(135deg);
}

.hamburger-menu.active .line-center {
    opacity: 0;
}

.hamburger-menu.active .line-bottom {
    -webkit-transform: translateY(-11px) rotate(-135deg);
    -ms-transform: translateY(-11px) rotate(-135deg);
    transform: translateY(-11px) rotate(-135deg);
}

/*======================
  Hero-area style
========================*/

.hero-area {
    background-image: url('../assets/hero/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 130px 0 160px;
    min-height: 100vh;
    position: relative;
}

.hero-area::before {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    background: -o-linear-gradient(338.93deg, #005651 -0.98%, #00322f 41.43%, #000000 100.81%);
    background: linear-gradient(111.07deg, #005651 -0.98%, #00322f 41.43%, #000000 100.81%);
    z-index: -1;
    top: 0;
    left: 0;
}

.hero-area .hero-image {
    background-image: url('../assets/hero/hero.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.hero-area .hero-button-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
    position: relative;
}

.hero-area .hero-button-bar .spacer-mobile {
    display: none;
}

.hero-area .hero-button-bar .spacer {
    flex: auto;
}

.hero-area .hero-button-bar .app-store {
    height: 51px;
}

.hero-area .hero-button-bar .google-play {
    height: 75px;
}

.hero-area .hero-button-bar .coming-soon {
    background-color: #732dc2;
    color: #e1f1ed;
    border-radius: 6px;
    white-space: nowrap;
    padding: 8px 14px;
    font-size: 13px;
    position: absolute;
    right: 0;
    top: -16px;
}

.hero-area .hero-text h2 {
    font-size: 69px;
    line-height: 89px;
    color: #fff;
    font-weight: 600;
    position: relative;
    margin-top: 50px;
}

.hero-area .hero-text h2::before {
    position: absolute;
    content: '';
    width: 342px;
    height: 6px;
    left: 0px;
    top: -50px;
    background: -webkit-gradient(
        linear,
        right top,
        left top,
        from(rgba(146, 146, 150, 0)),
        color-stop(65.1%, rgba(68, 212, 221, 0.723958)),
        to(#00ff94)
    );
    background: -o-linear-gradient(
        right,
        rgba(146, 146, 150, 0) 0%,
        rgba(68, 212, 221, 0.723958) 65.1%,
        #00ff94 100%
    );
    background: linear-gradient(
        270deg,
        rgba(146, 146, 150, 0) 0%,
        rgba(68, 212, 221, 0.723958) 65.1%,
        #00ff94 100%
    );
    border-radius: 3px;
}

.hero-area .hero-text p {
    font-size: 19px;
    line-height: 32px;
    color: #fff;
    opacity: 0.9;
    margin-top: 30px;
}

.hero-area .hero-text a.button {
    border: none;
    display: inline-block;
    padding: 14px 26px;
    /* -webkit-box-shadow: 0px 24px 44px rgba(98, 134, 251, 0.24);
    box-shadow: 0px 24px 44px rgba(98, 134, 251, 0.24); */
}

.hero-area .hero-text a.button + a.button {
    margin-left: 12px;
}

.hero-area .sale-timer {
    margin-top: 30px;
    margin-bottom: 60px;
}

.sale-timer {
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(#08664a),
        to(rgba(28, 65, 136, 0.33))
    );
    background: -o-linear-gradient(top, #08664a 0%, rgba(28, 65, 136, 0.33) 100%);
    background: linear-gradient(180deg, #08664a 0%, rgba(28, 65, 136, 0.33) 100%);
    max-width: 446px;
    margin: 0 auto;
    text-align: center;
    padding: 10px 0;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.5);
}

@media (min-width: 1024px) {

    .sale-timer {
        padding: 30px 20px;
    }
}

.sale-timer .timer-title {
    font-size: 18px;
    color: #fff;
    font-weight: 600;
    margin-bottom: 28px;
    margin-top: 0 !important;
}

.sale-timer .timer div {
    display: inline-block;
    height: 60px;
    width: 60px;
    line-height: 60px;
    background-color: #376974;
    margin-right: 28px;
    border-radius: 8px;
    font-weight: 600;
    font-size: 20px;
    color: #fff;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.sale-timer .timer div:last-child {
    margin-right: 0;
}

.sale-timer .timer div:hover {
    background-color: #26a08a;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.sale-timer .timer div span {
    display: block;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #fff;
    margin-top: 10px;
}

.color-change {
    background-color: #26a08a !important;
}

.home-range-area {
    margin-top: 60px;
}

.home-range-area .label-box span {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
}

.home-range-area .label-box span strong {
    font-weight: 500;
}

.home-range-area .ion-range {
    position: relative;
}

.home-range-area .ion-range input {
    width: 100%;
}

.home-range-area .ion-range .irs--round {
    margin-top: -15px;
}

.home-range-area .ion-range .position-box {
    position: absolute;
    top: 74px;
    min-width: 100px;
    font-size: 14px;
    font-weight: 400;
    color: white;
    text-align: center;
    position: absolute;
    transform: translateX(-50%);
    margin-left: 7px;
}

.home-range-area .ion-range .position-box::before {
    content: '';
    position: absolute;
    height: 37px;
    width: 1px;
    background-color: white;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
}

.home-icon-area {
    max-width: 330px;
    margin: 110px auto 0 auto;
    position: relative;
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    padding: 1rem 0;
}

.home-icon-area img {
    width: 36px;
    filter: drop-shadow(-3px 1px 8px rgba(255, 255, 255, 0.75));
}

.home-icon-area div {
    font-size: 85%;
    margin-top: 0.5rem;
    line-height: 2;
}

.home-icon-area a {
    font-size: 85%;
    font-weight: 600;
    color: #a799ff;
    margin-top: 0.5rem;
}

.home-icon-area a:hover {
    color: #c2b9fd;
}

.home-icon-area::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 2px;
    width: 316px;
    background: -o-radial-gradient(50% 50%, 50% 50%, #a77dff 0%, rgba(196, 196, 196, 0) 100%);
    background: radial-gradient(50% 50% at 50% 50%, #a77dff 0%, rgba(196, 196, 196, 0) 100%);
}

.home-icon-area::after {
    position: absolute;
    content: '';
    top: 100%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    height: 2px;
    width: 170px;
    background: -o-radial-gradient(50% 50%, 50% 50%, #a77dff 0%, rgba(196, 196, 196, 0) 100%);
    background: radial-gradient(50% 50% at 50% 50%, #a77dff 0%, rgba(196, 196, 196, 0) 100%);
}

/*======================
  Hero-area style
========================*/

/*======================
  edtoken-area style
========================*/

.edtoken-area {
    padding: 100px 0;
    position: relative;
}

.edtoken-area .img-area {
    border-radius: 50%;
    border: 1px solid rgba(1, 93, 83, 1);
    padding: 50px;
    height: 550px;
    width: 550px;
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.edtoken-area .img-area::before,
.edtoken-area .img-area::after {
    position: absolute;
    content: '';
    top: -118px;
    left: 50%;
    height: 762px;
    width: 762px;
    border: 1px solid rgba(36, 174, 108, 0.3);
    border-radius: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.edtoken-area .img-area::after {
    height: 930px;
    width: 930px;
    top: -200px;
    border-color: rgba(26, 171, 145, 0.15);
    z-index: -1;
}

.edtoken-area .edtoken-text-box .item {
    text-align: right;
    margin-right: 48px;
    position: relative;
    z-index: 1;
}

.edtoken-area .edtoken-text-box .item.left-align {
    margin-left: 48px;
    margin-right: 0;
}

.edtoken-area .edtoken-text-box .item.left-align.item2 {
    margin-left: 93px;
}

.edtoken-area .edtoken-text-box .item.left-align {
    text-align: left;
}

.edtoken-area .edtoken-text-box .item h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 12px;
}

.edtoken-area .edtoken-text-box .item p {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: rgba(141, 134, 155, 1);
}

.edtoken-area .item .item-icon {
    height: 44px;
    width: 44px;
    line-height: 44px;
    text-align: center;
    background: -o-linear-gradient(338.93deg, #015d53 -0.98%, #01b56e 100.81%);
    background: linear-gradient(111.07deg, #015d53 -0.98%, #01b56e 100.81%);
    border-radius: 50%;
    position: absolute;
}

.edtoken-area .edtoken-text-box.box-1 .item.item-mar {
    margin-right: 90px;
}

.edtoken-area .edtoken-text-box.box-1 .item .item-icon {
    right: 0;
}

.edtoken-area .edtoken-text-box.box-2 .item .item-icon {
    left: 0;
}

.edtoken-area .edtoken-text-box.box-1 .item .item-icon.item1 {
    right: -104px;
}

.edtoken-area .edtoken-text-box.box-1 .item .item-icon.item2 {
    right: -65px;
}

.edtoken-area .edtoken-text-box.box-1 .item .item-icon.item3 {
    right: -74px;
}

.edtoken-area .edtoken-text-box.box-2 .item .item-icon.item4 {
    left: -101px;
}

.edtoken-area .edtoken-text-box.box-2 .item .item-icon.item5 {
    left: -71px;
}

.edtoken-area .edtoken-text-box.box-2 .item .item-icon.item6 {
    left: -73px;
}

.edtoken-area .edtoken-text-box .item .item-icon img {
    position: relative;
}

.edtoken-area .edtoken-text-box.box-1 .item .item-icon.item1 img {
    top: -2px;
    left: 0;
    width: 24px;
}

.edtoken-area .edtoken-text-box.box-1 .item .item-icon.item2 img {
    top: -1px;
    left: 0;
    width: 24px;
}

.edtoken-area .edtoken-text-box.box-1 .item .item-icon.item3 img {
    top: -1px;
    left: 1px;
}

.edtoken-area .edtoken-text-box.box-2 .item .item-icon.item4 img {
    top: -2px;
    left: 0;
    width: 24px;
}

.edtoken-area .edtoken-text-box.box-2 .item .item-icon.item5 img {
    top: -3px;
    left: 1px;
    width: 24px;
}

.edtoken-area .edtoken-text-box.box-2 .item .item-icon.item6 img {
    top: -2px;
    left: 0;
    width: 24px;
}

/*======================
  token-area style
========================*/

.section-title {
    text-align: center;
    margin-bottom: 50px;
}

.section-title h2 {
    font-size: 40px;
    color: #fff;
    font-weight: 700;
    position: relative;
}

.section-title h2::after {
    position: absolute;
    content: '';
    height: 3px;
    width: 126px;
    background-color: #fff;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: -15px;
    border-radius: 3px;
}

.section-title p {
    font-size: 18px;
    line-height: 27px;
    color: #fff;
    margin-top: 44px;
}

.token-area {
    padding: 100px 0;
    position: relative;
    background-image: url('../assets/advantages/token-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.token-area::before,
.sale-area::before,
.document-area::before,
.schools-area::before,
.faq-area::before,
footer::before {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    background: -o-linear-gradient(338.93deg, #015d53 -0.98%, #01b56e 100.81%);
    background: linear-gradient(111.07deg, #015d53 -0.98%, #01b56e 100.81%);
    z-index: -1;
    top: 0;
    left: 0;
}

.token-area .section-title {
    margin-bottom: 70px;
}

.token-area .token-box {
    padding: 28px;
    text-align: center;
    height: 100%;
    border-radius: 8px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.token-area .token-box .icon-box {
    padding: 15px;
    border: 2px solid rgba(255, 255, 255, 1);
    border-radius: 50%;
    width: 108px;
    height: 108px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 auto;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.token-area .token-box:hover.token-box .icon-box {
    border-color: rgba(1, 93, 83, 1);
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.token-area .token-box .icon-box .img-area {
    height: 68px;
    width: 68px;
    line-height: 68px;
    text-align: center;
    background: -o-linear-gradient(338.93deg, #015d53 -0.98%, #01b56e 100.81%);
    background: linear-gradient(111.07deg, #015d53 -0.98%, #01b56e 100.81%);
    border-radius: 50%;
}

.token-area .token-box .icon-box .img-area img {
    position: relative;
}

.token-area .token-box .icon-box .img-area.img-status-up img {
    top: -1px;
    left: 0;
    width: 44px;
}

.token-area .token-box .icon-box .img-area.img-book img {
    top: 0;
    left: 1px;
    width: 40px;
}

.token-area .token-box .icon-box .img-area.img-tv img {
    top: 0;
    left: 1px;
    width: 40px;
}

.token-area .token-box .icon-box .img-area.img-wallet img {
    top: -2px;
    left: 1px;
}

.token-area .token-box h3 {
    margin-top: 30px;
    font-size: 24px;
    font-weight: 600;
    color: #fff;
    position: relative;
}

.token-area .token-box h3::after {
    position: absolute;
    content: '';
    bottom: -21px;
    height: 1px;
    width: 137px;
    background-color: #fff;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.token-area .token-box p {
    margin-top: 40px;
    font-size: 16px;
    color: #fff;
}

.token-area .token-box:hover {
    background-color: rgba(0, 0, 0, 0.7);
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.token-area .token-box:hover.token-box p,
.token-area .token-box:hover.token-box h3,
.token-area .token-box:hover.token-box .icon-box .img-area {
    opacity: 0.7;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.token-area .app-carousel {

}

.token-area .app-carousel .carousel-image-wrapper {
   display: flex !important; /* overriding the inline styles of the carousel */
   justify-content: center;
}

.token-area .app-carousel .carousel-image {
    max-width: 100%;
    max-height: 500px;
}

.token-area .app-carousel .slick-dots {
    bottom: -40px;
}

.token-area .app-carousel .slick-dots li button:before {
    color: white;
}

.token-area .app-carousel .slick-dots li.slick-active button:before {
    color: white;
}

/*======================
  funds-area style
========================*/

.funds-area {
    padding: 100px 0;
}

.funds-area .section-title h2,
.team-area .section-title h2,
.partners-area .section-title h2,
.contact-area .section-title h2,
.edtoken-area .section-title h2,
.edtoken-area .edtoken-text-box .item h3,
.road-map-area .section-title h2,
.road-map-area .map-box .text-box h4 {
    background: -webkit-linear-gradient(111.07deg, #01b56e -0.98%, #015d53 100.81%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.funds-area .section-title h2::after,
.sale-area .section-title h2::after,
.document-area .section-title h2::after,
.team-area .section-title h2::after,
.faq-area .section-title h2::after,
.partners-area .section-title h2::after,
.contact-area .section-title h2::after,
.edtoken-area .section-title h2::after,
.road-map-area .section-title h2::after {
    background: -o-linear-gradient(338.93deg, #015d53 -0.98%, #01b56e 100.81%);
    background: linear-gradient(111.07deg, #015d53 -0.98%, #01b56e 100.81%);
}

.funds-area .section-title p,
.team-area .section-title p,
.partners-area .section-title p,
.contact-area .section-title p,
.edtoken-area .section-title p,
.road-map-area .section-title p {
    color: rgba(141, 134, 155, 1);
}

.funds-area .chart-area {
    background-color: #f8fbfe;
    border-radius: 12px;
    padding: 75px;
    height: 100%;
}

.funds-area .chart-area .chart-title {
    font-weight: 600;
    font-size: 150%;
    margin-bottom: 2rem;
    color: #555;
}

.funds-area .chart-area .img-area {
    margin-bottom: 60px;
}

.funds-area .chart-area .img-area .pie-chart {
    width: 362px;
}

.funds-area .chart-area .parcent-area {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.funds-area .chart-area .parcent-area span {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
}

.funds-area .chart-area .parcent-area p {
    font-size: 16px;
    color: rgba(141, 134, 155, 1);
}

.funds-area .chart-area .parcent-area ul li {
    position: relative;
    padding-left: 16px;
}

.funds-area .chart-area .parcent-area ul li::before {
    position: absolute;
    content: '';
    height: 100%;
    width: 8px;
    background-color: #00d89e;
    left: 0;
    top: 0;
    border-radius: 7px;
}

.funds-area .chart-area .parcent-area ul li.p2::before {
    background-color: #5637c8;
}

.funds-area .chart-area .parcent-area ul li.p3::before {
    background-color: #3d88fb;
}

.funds-area .chart-area .parcent-area ul li.p4::before {
    background-color: #9116cd;
}

.funds-area .chart-area .parcent-area ul li.p5::before {
    background-color: #fc3352;
}

.funds-area .chart-area .parcent-area ul li.p6::before {
    background-color: rgba(187, 26, 201, 1);
}

.funds-area .chart-area .parcent-area ul li.p7::before {
    background-color: rgba(255, 131, 16, 1);
}

.funds-area .chart-area .parcent-area ul li.p8::before {
    background-color: rgba(39, 203, 239, 1);
}

.funds-area .chart-area .parcent-area ul li.p9::before {
    background-color: rgba(137, 94, 243, 1);
}

.funds-area .chart-area .parcent-area ul li.p10::before {
    background-color: rgba(172, 223, 53, 1);
}

.funds-area .chart-area .parcent-area ul li.p11::before {
    background-color: rgba(107, 151, 216, 1);
}

/*======================
  sale-area style
========================*/

.sale-area {
    background-image: url('../assets/token-sale/sale-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    padding: 100px 0;
}

.token-box-wrapper {
    position: relative;
}

.sale-box-wrapper {
    margin-bottom: 60px;
}

.token-box-wrapper .item {
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.75);
    margin-top: 30px;
    border-radius: 8px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.token-box-wrapper .item > span {
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    color: #fff;
}

.token-box-wrapper .item > p {
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    font-weight: 500;
    margin-top: 12px;
}

.token-box-wrapper .item:hover {
    background-color: rgba(0, 255, 178, 0.5);
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.token-box-wrapper.disabled .item {
    opacity: 0.7;
}

.token-box-wrapper.disabled::before {
    display: none;
}

.token-box-wrapper.disabled .item:hover {
    background-color: rgba(0, 0, 0, 0.75);
}

.token-counter-box .sale-timer {
    margin: 30px 0 0 0;
    padding: 40px 35px;
    background: rgba(0, 0, 0, 0.75);
    border: none;
    max-width: 100%;
}

.token-counter-box .sale-timer .timer div {
    margin-right: 40px;
}

.token-counter-box .sale-timer .timer div:last-child {
    margin-right: 0px;
}

.token-counter-box .sale-header {
    margin-bottom: 25px;
    color: white;
}

.token-counter-box .sale-timer .timer {
    /* border-bottom: 1px solid #9284ae;
    padding-bottom: 25px; */
}

.home-range-area.token-range {
    margin-top: 12px;
}

.home-range-area.token-range .ion-range .irs--round .irs-handle {
    display: none !important;
}

.home-range-area.token-range .position-box {
    margin-left: 0;
}

.home-range-area.token-range .position-box::before {
    height: 35px;
    margin-top: 2px;
}

.token-counter-box a.button {
    padding: 12px 20px;
    margin-top: 88px;
    -webkit-box-shadow: 0px 24px 44px rgb(98 134 251 / 24%);
    box-shadow: 0px 24px 44px rgb(98 134 251 / 24%);
}

.clender-wrapper::before {
    position: absolute;
    content: '';
    width: 1047px;
    height: 5px;
    background: -o-radial-gradient(50% 50%, 50% 50%, #8e9ffc 0%, rgba(196, 196, 196, 0) 100%);
    background: radial-gradient(50% 50% at 50% 50%, #8e9ffc 0%, rgba(196, 196, 196, 0) 100%);
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.clender-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-top: 60px;
    position: relative;
}

.clender-wrapper .clender-box {
    text-align: center;
    max-width: 145px;
    background-color: rgba(9, 35, 57, 0.5);
    border-radius: 4px;
    position: relative;
}

.clender-wrapper .clender-box:hover.clender-box .header {
    background: rgba(38, 160, 138, 1);
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.clender-wrapper .clender-box::before {
    position: absolute;
    content: '';
    height: 9px;
    width: 9px;
    background: -o-linear-gradient(338.93deg, #015d53 -0.98%, #01b56e 100.81%);
    background: linear-gradient(111.07deg, #015d53 -0.98%, #01b56e 100.81%);
    top: 50%;
    left: -58px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    border-radius: 50%;
}

.clender-wrapper .clender-box:first-child::before {
    display: none;
}

.clender-wrapper .clender-box .header {
    padding: 12px;
    background-color: #000;
    color: #fff;
    font-size: 15px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.clender-wrapper .clender-box .bonus-box {
    padding: 10px 13px;
}

.clender-wrapper .clender-box .bonus-box > span {
    font-size: 18px;
    line-height: 27px;
    font-weight: 600;
    color: #fff;
}

.clender-wrapper .clender-box .bonus-box > p {
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    margin-top: 10px;
}

.clender-wrapper .clender-box .bonus-box .value-number {
    font-size: 18px;
    font-weight: 600;
}

/*======================
  road-map-area style
========================*/

.road-map-area {
    padding: 100px 0;
    background-color: #fbfbfb;
    position: relative;
    z-index: 1;
}

.road-map-area .map-box {
    max-width: 184px;
    margin: 0 auto;
    text-align: center;
}

.road-map-area .map-box .text-box .date {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000;
    margin-bottom: 12px;
}

.road-map-area .map-box .radio-box {
    position: relative;
    z-index: 99;
}

.road-map-area .line-area-wrapper {
    position: relative;
    top: 26px;
}

.road-map-area .map-box .unactive-radio,
.road-map-area .map-box .active-radio {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    top: 15px;
}

.road-map-area .map-box .unactive-radio span {
    height: 12px;
    width: 12px;
    text-align: center;
    background: rgba(1, 179, 109, 1);
    border-radius: 50%;
    display: inline-block;
}

.road-map-area .map-box .radio-box.active .unactive-radio {
    display: none;
}

.road-map-area .map-box .radio-box.active .active-radio {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.road-map-area .map-box .unactive-radio,
.road-map-area .map-box .active-radio {
    border: 2px solid rgba(1, 179, 109, 1);
    height: 20px;
    width: 20px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50%;
    text-align: center;
}

.road-map-area .map-box .active-radio {
    display: none;
}

.road-map-area .map-box .active-radio {
    background: -o-linear-gradient(338.93deg, #015d53 -0.98%, #01b56e 100.81%);
    background: linear-gradient(111.07deg, #015d53 -0.98%, #01b56e 100.81%);
    border: none;
    display: none;
}

.road-map-area .map-box .active-radio span {
    position: relative;
    left: 1px;
    top: -1px;
}

.road-map-area .row-top {
    margin-top: 54px;
    flex-direction: row-reverse;
    justify-content: space-around;
}

.road-map-area .row-top.top-2 {
    margin-top: 31px;
    flex-direction: row;
}

.road-map-area .map-box .text-box {
    padding: 16px;
    background-color: #fff;
    margin-top: 55px;
    -webkit-box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.03);
    box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.03);
    border-radius: 6px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.road-map-area .map-box .text-box:hover {
    background: -o-linear-gradient(338.93deg, #015d53 -0.98%, #01b56e 100.81%);
    background: linear-gradient(111.07deg, #015d53 -0.98%, #01b56e 100.81%);
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.road-map-area .map-box .text-box h4 {
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 8px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.road-map-area .map-box .text-box p {
    font-weight: 500;
    font-size: 13px;
    line-height: 21px;
    color: #8d869b;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.road-map-area .map-box .text-box:hover h4 {
    color: #fff;
    background-color: transparent;
    -webkit-text-fill-color: white;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.road-map-area .map-box .text-box:hover p {
    color: #fff;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.road-map-area .line-img-area {
    position: absolute;
    top: 200px;
    left: 234px;
    z-index: -1;
}

/*======================
  document-area style
========================*/

.document-area {
    position: relative;
    padding: 100px 0;
    background-image: url('../assets/documents/document-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
}

.document-area .document-card {
    text-align: center;
}

.document-area .document-card .img-fluid {
    width: 247px;
}

.document-area .document-card .text-box {
    margin-top: 45px;
}

.document-area .document-card .text-box h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #fff;
}

.document-area .document-card .text-box p {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #fff;
    margin-top: 5px;
}

.document-area .document-card .text-box .download-box {
    margin-top: 20px;
    display: inline-block;
    height: 40px;
    line-height: 38px;
    width: 64px;
    background: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        -o-linear-gradient(338.93deg, #015d53 -0.98%, #01b56e 100.81%);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        linear-gradient(111.07deg, #015d53 -0.98%, #01b56e 100.81%);
    border-radius: 6px;
}

.document-area .document-card .text-box .download-box img {
    position: relative;
    top: -2px;
}

.document-area .document-card .text-box span {
    display: block;
    color: #fff;
    font-size: 14px;
    line-height: 21px;
    margin-top: 16px;
}

.document-area .document-card img {
    -webkit-filter: drop-shadow(0px 20px 40px rgba(0, 0, 0, 0.16));
    filter: drop-shadow(0px 20px 40px rgba(0, 0, 0, 0.16));
}

/*team area style*/

.team-area {
    padding: 100px 0;
}

.team-area .team-card {
    text-align: center;
    padding: 28px;
    border-radius: 8px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

/*======================
  team-area style
========================*/

.team-area .team-card .img-area {
    padding: 17px;
    border: 3px solid rgba(244, 244, 244, 1);
    display: inline-block;
    border-radius: 50%;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.team-area .team-card .img-area img {
    height: 164px;
    width: 164px;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 50%;
}

.team-area .team-card .info-area {
    margin-top: 28px;
}

.team-area .team-card .info-area h3 {
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    color: #000;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.team-area .team-card .info-area p {
    font-size: 18px;
    line-height: 27px;
    margin-top: 16px;
    color: #8d869b;
}

.team-area .team-card .info-area .team-icon a {
    display: inline-block;
    margin: 20px 3px 0 3px;
    color: #97c8bf;
    height: 40px;
    width: 40px;
    line-height: 40px;
    border-radius: 6px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.team-area .team-card .info-area .team-icon a:hover {
    background-color: rgba(62, 146, 130, 0.12);
    color: #348677;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.team-icon a:nth-child(1) img {
    /* facebook */
    width: 12px;
    position: relative;
    top: -1px;
}

.team-icon a:nth-child(2) img {
    /* twitter */
    width: 24px;
    position: relative;
    top: -1px;
}

.team-icon a:nth-child(3) img {
    /* linked in */
    width: 21px;
    position: relative;
    top: 0;
}

.team-area .team-card:hover {
    -webkit-box-shadow: 0px 40px 68px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 40px 68px rgba(0, 0, 0, 0.05);
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.team-area .team-card:hover.team-card .img-area {
    border-color: rgba(151, 200, 191, 1);
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.team-area .team-card:hover.team-card .info-area h3 {
    color: #348677;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.team-area .investor-board h2 {
    font-size: 32px;
    margin-top: 80px;
}

.team-area .investor-board h2::after {
    background: rgba(151, 200, 191, 1);
}

/*======================
  schools-area style
========================*/

.schools-area {
    position: relative;
    padding: 100px 0;
    background-image: url('../assets/schools/schools-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
}

.schools-area .section-title p {
    color: #f8fbfe;
}

.schools-area .school-list {
    font-size: 15px;
}

.schools-area .school {
    color: white;
}

.schools-area .school .location {
    display: block;
    color: white;
    opacity: 0.9;
    font-size: 13px;
    margin-bottom: 15px;
}

/*======================
  partners-area style
========================*/

.partners-area {
    padding: 100px 0;
    /* background: #fbfbfb; */
    background: white; /* cannot find correct logos with transparent background */
    position: relative;
    z-index: 1;
}

.partners-area .partner-list {
    align-items: center;
}

.partners-area .partner {
    text-align: center;
}

.partners-area .partner img {
    max-width: 100%;
}


/*======================
  faq-area style
========================*/

.faq-area {
    position: relative;
    padding: 100px 0;
    background-image: url('../assets/faq/faq-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
}

.faq-area .section-title p {
    color: #f8fbfe;
}

.faq-area .tab-area .nav {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 6px;
    margin: 0 auto;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border: none;
    max-width: 510px;

    display: none; /* TODO: REMOVE THIS */
}

.faq-area .tab-area .nav .nav-item.nav-link {
    color: white;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 16px;
    border: none;
    margin-bottom: 0;
    padding-bottom: 9px;
}

.faq-area .tab-area .nav .nav-item.nav-link:nth-child(1) {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.faq-area .tab-area .nav .nav-item.nav-link.active {
    background: rgba(255, 255, 255, 0.15);
    border-radius: 0;
}

.faq-area .tab-area .nav .nav-item.nav-link:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.faq-area .tab-area .nav .nav-item.nav-link:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.faq-area .tab-area .accordion .card {
    background-color: transparent;
    border: none;
    margin-top: 16px;
}

.faq-area .tab-area .accordion .card .card-header {
    padding: 15px;
    background: rgba(0, 54, 45, 0.7);
    border-radius: 6px;
    color: white;
    font-size: 20px;
    line-height: 30px;
    text-decoration: none;
    position: relative;
    padding-left: 50px;
    cursor: pointer;
}

.faq-area .tab-area .accordion .card.toggled .card-header {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.faq-area .tab-area .accordion .card .card-header::before {
    position: absolute;
    content: '';
    left: 20px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 12px;
    width: 12px;
    background-color: transparent;
    border-radius: 4px;
    border: 2px solid white;
    transition: background-color 250ms ease-in-out;
}

.faq-area .tab-area .accordion .card.toggled .card-header::before {
    background-color: #fff;
}

.faq-area .tab-area .accordion .card .card-body {
    padding: 20px;
    background: rgba(255, 255, 255, 0.15);
    font-size: 16px;
    color: rgba(248, 251, 254, 1);
    line-height: 24px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

/*======================
  contact-area style
========================*/

.contact-area {
    padding: 100px 0;
    background: #fbfbfb;
    position: relative;
    z-index: 1;
}

.contact-option-wrapper {
    background-color: rgba(255, 255, 255, 1);
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #f4f4f4;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.contact-option-wrapper .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.contact-option-wrapper .item .icon-area {
    height: 44px;
    width: 44px;
    line-height: 44px;
    border-radius: 50%;
    text-align: center;
    background: -o-linear-gradient(338.93deg, #015d53 -0.98%, #01b56e 100.81%);
    background: linear-gradient(111.07deg, #015d53 -0.98%, #01b56e 100.81%);
    margin-right: 12px;
}

.contact-option-wrapper .item a {
    font-weight: 500;
    font-size: 20px;
    color: #000;
}

.contact-area form input,
.contact-area form textarea {
    width: 100%;
    height: 48px;
    background: #ffffff;
    border: 1px solid #eae9e9;
    padding: 12px;
    border-radius: 6px;
    font-size: 16px;
    color: #000;
    font-weight: 500;
}

.contact-area form textarea {
    resize: none;
    height: 134px;
}

.contact-area form input::-webkit-input-placeholder,
.contact-area form textarea::-webkit-input-placeholder {
    color: rgba(181, 181, 181, 1);
    font-weight: normal;
}

.contact-area form input::-moz-placeholder,
.contact-area form textarea::-moz-placeholder {
    color: rgba(181, 181, 181, 1);
    font-weight: normal;
}

.contact-area form input:-ms-input-placeholder,
.contact-area form textarea:-ms-input-placeholder {
    color: rgba(181, 181, 181, 1);
    font-weight: normal;
}

.contact-area form input::-ms-input-placeholder,
.contact-area form textarea::-ms-input-placeholder {
    color: rgba(181, 181, 181, 1);
    font-weight: normal;
}

.contact-area form input::placeholder,
.contact-area form textarea::placeholder {
    color: rgba(181, 181, 181, 1);
    font-weight: normal;
}

.contact-area form {
    padding: 25px 55px 55px 55px;
    background-color: #fff;
    margin-top: 50px;
    border: 1px solid rgba(244, 244, 244, 1);
    border-radius: 10px;
    -webkit-box-shadow: 0px 60px 120px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 60px 120px rgba(0, 0, 0, 0.05);
    position: relative;
}

.contact-area form input:focus,
.contact-area form textarea:focus {
    border-color: rgba(1, 93, 83, 1);
}

.contact-area .contact-btn .button {
    padding: 12px 20px;
    width: 100%;
    display: block;
    font-size: 16px;
    font-weight: 600;
}

.contact-area .contact-btn .button[disabled] {
    filter: grayscale(0.75) brightness(1.2);
}

.contact-area form .error-message {
    color: red;
    font-size: 80%;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
}

.contact-shape-box .shape {
    position: absolute;
    z-index: -1;
}

.contact-shape-box .shape.shape1 {
    top: -165px;
    left: -124px;
}

.contact-shape-box .shape.shape2 {
    top: 50px;
    left: -78px;
}

.contact-shape-box .shape.shape3 {
    top: 330px;
    left: -132px;
}

.contact-shape-box .shape.shape4 {
    top: 445px;
    left: -60px;
}

.contact-shape-box .shape.shape5 {
    top: 475px;
    left: 0px;
}

.contact-shape-box .shape.shape6 {
    top: 475px;
    left: 676px;
}

.contact-shape-box .shape.shape7 {
    top: 475px;
    right: -22px;
}

.contact-shape-box .shape.shape8 {
    top: 448px;
    right: -124px;
}

.contact-shape-box .shape.shape9 {
    top: 256px;
    right: -57px;
}

.contact-shape-box .shape.shape10 {
    top: 222px;
    right: -150px;
}

.contact-shape-box .shape.shape11 {
    top: 60px;
    right: -90px;
}

.road-map-area .contact-shape-box .shape.shape1 {
    top: 194px;
    left: -188px;
}

.road-map-area .contact-shape-box .shape.shape2 {
    top: 475px;
    left: -110px;
}

.road-map-area .contact-shape-box .shape.shape3 {
    top: 716px;
    left: -150px;
}

.road-map-area .contact-shape-box .shape.shape4 {
    top: 830px;
    left: 29px;
}

.road-map-area .contact-shape-box .shape.shape5 {
    top: 810px;
    right: -167px;
    left: auto;
}

.road-map-area .contact-shape-box .shape.shape6 {
    top: 620px;
    right: -56px;
    left: auto;
}

.road-map-area .contact-shape-box .shape.shape7 {
    top: 456px;
    right: -92px;
    left: auto;
}

/*======================
  footer-area style
========================*/

footer {
    padding: 100px 0 0;
    background-image: url('../assets/footer/footer-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.footer-icon a:nth-child(1) img {
    /* facebook */
    width: 15px;
    position: relative;
    top: -1px;
}

.footer-icon a:nth-child(2) img {
    /* twitter */
    width: 24px;
    position: relative;
    top: -1px;
}

.footer-icon a:nth-child(3) img {
    /* instagram */
    width: 22px;
    position: relative;
    top: -1px;
}

.footer-icon a:nth-child(4) img {
    /* linked in */
    width: 20px;
    position: relative;
    top: -1px;
}

.footer-icon a:nth-child(5) img {
    /* youtube */
    width: 26px;
    position: relative;
    top: -1px;
}

footer .footer-box h2 {
    font-weight: 700;
    font-size: 36px;
    line-height: 54px;
    color: #fff;
    margin-bottom: 80px;
}

footer .footer-box .sub-box {
    max-width: 575px;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 50px;
}

footer .footer-box .sub-box .input-box {
    width: 100%;
}

footer .footer-box .sub-box .input-box input {
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 8px;
    border: none;
    padding: 15px;
    font-size: 16px;
    color: #fff;
}

footer .footer-box .sub-box .input-box .error-message {
    text-align: left;
    position: absolute;
    color: white;
    font-size: 80%;
    margin-top: 0.5rem;
    margin-left: 0.9rem;
}

footer .footer-box .sub-box .input-box input::-webkit-input-placeholder {
    color: rgba(197, 181, 214, 1);
}

footer .footer-box .sub-box .input-box input::-moz-placeholder {
    color: rgba(197, 181, 214, 1);
}

footer .footer-box .sub-box .input-box input:-ms-input-placeholder {
    color: rgba(197, 181, 214, 1);
}

footer .footer-box .sub-box .input-box input::-ms-input-placeholder {
    color: rgba(197, 181, 214, 1);
}

footer .footer-box .sub-box .input-box input::placeholder {
    color: rgba(197, 181, 214, 1);
}

footer .footer-box .sub-box .button {
    padding: 15px 20px;
    background: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        -o-linear-gradient(338.93deg, #015d53 -0.98%, #01b56e 100.81%);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        linear-gradient(111.07deg, #015d53 -0.98%, #01b56e 100.81%);
    -webkit-box-shadow: 0px 20px 40px rgba(232, 112, 242, 0.1);
    box-shadow: 0px 20px 40px rgba(232, 112, 242, 0.1);
    border-radius: 6px;
    margin-left: 20px;
}

footer .footer-box .sub-box .button[disabled] {
    filter: grayscale(0.75) brightness(1.2);
}

footer .footer-box p {
    color: #e0e0e0;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 50px;
}

footer .footer-box .footer-icon {
    margin-bottom: 80px;
}

footer .footer-box .footer-icon a {
    display: inline-block;
    height: 40px;
    width: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 8px;
    background-color: #000;
    color: #fff;
    font-size: 24px;
    margin-right: 20px;
}

footer .footer-box .footer-icon a:last-child {
    margin-right: 0;
}

footer .copyright {
    text-align: center;
    color: rgba(224, 224, 224, 1);
    font-size: 13px;
    padding: 30px 0;
    line-height: 1.4;
    border-top: 1.5px solid #414073;
}

footer .copyright a {
    color: rgba(224, 224, 224, 1);
    font-weight: 600;
}

#features,
#advantages,
#allocation,
#sale,
#roadmap,
#documents,
#team,
#schools,
#partners,
#faq,
#contact {
    scroll-margin-top: 90px;
}
