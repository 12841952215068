/*
**margin 5px - 100px
**padding 5px - 100px
*/

.mt_5 {
    margin-top: 5px;
}
.mt_10 {
    margin-top: 10px;
}
.mt_15 {
    margin-top: 15px;
}
.mt_20 {
    margin-top: 20px;
}
.mt_25 {
    margin-top: 25px;
}
.mt_30 {
    margin-top: 30px;
}
.mt_35 {
    margin-top: 35px;
}
.mt_40 {
    margin-top: 40px;
}
.mt_45 {
    margin-top: 45px;
}
.mt_50 {
    margin-top: 50px;
}
.mt_55 {
    margin-top: 55px;
}
.mt_60 {
    margin-top: 60px;
}
.mt_65 {
    margin-top: 65px;
}
.mt_70 {
    margin-top: 70px;
}
.mt_75 {
    margin-top: 75px;
}
.mt_80 {
    margin-top: 80px;
}
.mt_85 {
    margin-top: 85px;
}
.mt_90 {
    margin-top: 90px;
}
.mt_95 {
    margin-top: 95px;
}
.mt_100 {
    margin-top: 100px;
}
.mb_5 {
    margin-bottom: 5px;
}
.mb_10 {
    margin-bottom: 10px;
}
.mb_15 {
    margin-bottom: 15px;
}
.mb_20 {
    margin-bottom: 20px;
}
.mb_25 {
    margin-bottom: 25px;
}
.mb_30 {
    margin-bottom: 30px;
}
.mb_35 {
    margin-bottom: 35px;
}
.mb_40 {
    margin-bottom: 40px;
}
.mb_45 {
    margin-bottom: 45px;
}
.mb_50 {
    margin-bottom: 50px;
}
.mb_55 {
    margin-bottom: 55px;
}
.mb_60 {
    margin-bottom: 60px;
}
.mb_65 {
    margin-bottom: 65px;
}
.mb_70 {
    margin-bottom: 70px;
}
.mb_75 {
    margin-bottom: 75px;
}
.mb_80 {
    margin-bottom: 80px;
}
.mb_85 {
    margin-bottom: 85px;
}
.mb_90 {
    margin-bottom: 90px;
}
.mb_95 {
    margin-bottom: 95px;
}
.mb_100 {
    margin-bottom: 100px;
}
.mr_5 {
    margin-right: 5px;
}
.mr_10 {
    margin-right: 10px;
}
.mr_15 {
    margin-right: 15px;
}
.mr_20 {
    margin-right: 20px;
}
.mr_25 {
    margin-right: 25px;
}
.mr_30 {
    margin-right: 30px;
}
.mr_35 {
    margin-right: 35px;
}
.mr_40 {
    margin-right: 40px;
}
.mr_45 {
    margin-right: 45px;
}
.mr_50 {
    margin-right: 50px;
}
.mr_55 {
    margin-right: 55px;
}
.mr_60 {
    margin-right: 60px;
}
.mr_65 {
    margin-right: 65px;
}
.mr_70 {
    margin-right: 70px;
}
.mr_75 {
    margin-right: 75px;
}
.mr_80 {
    margin-right: 80px;
}
.mr_85 {
    margin-right: 85px;
}
.mr_90 {
    margin-right: 90px;
}
.mr_95 {
    margin-right: 95px;
}
.mr_100 {
    margin-right: 100px;
}
.ml_5 {
    margin-left: 5px;
}
.ml_10 {
    margin-left: 10px;
}
.ml_15 {
    margin-left: 15px;
}
.ml_20 {
    margin-left: 20px;
}
.ml_25 {
    margin-left: 25px;
}
.ml_30 {
    margin-left: 30px;
}
.ml_35 {
    margin-left: 35px;
}
.ml_40 {
    margin-left: 40px;
}
.ml_45 {
    margin-left: 45px;
}
.ml_50 {
    margin-left: 50px;
}
.ml_55 {
    margin-left: 55px;
}
.ml_60 {
    margin-left: 60px;
}
.ml_65 {
    margin-left: 65px;
}
.ml_70 {
    margin-left: 70px;
}
.ml_75 {
    margin-left: 75px;
}
.ml_80 {
    margin-left: 80px;
}
.ml_85 {
    margin-left: 85px;
}
.ml_90 {
    margin-left: 90px;
}
.ml_95 {
    margin-left: 95px;
}
.ml_100 {
    margin-left: 100px;
}
.pt_5 {
    padding-top: 5px;
}
.pt_10 {
    padding-top: 10px;
}
.pt_15 {
    padding-top: 15px;
}
.pt_20 {
    padding-top: 20px;
}
.pt_25 {
    padding-top: 25px;
}
.pt_30 {
    padding-top: 30px;
}
.pt_35 {
    padding-top: 35px;
}
.pt_40 {
    padding-top: 40px;
}
.pt_45 {
    padding-top: 45px;
}
.pt_50 {
    padding-top: 50px;
}
.pt_55 {
    padding-top: 55px;
}
.pt_60 {
    padding-top: 60px;
}
.pt_65 {
    padding-top: 65px;
}
.pt_70 {
    padding-top: 70px;
}
.pt_75 {
    padding-top: 75px;
}
.pt_80 {
    padding-top: 80px;
}
.pt_85 {
    padding-top: 85px;
}
.pt_90 {
    padding-top: 90px;
}
.pt_95 {
    padding-top: 95px;
}
.pt_100 {
    padding-top: 100px;
}
.pb_5 {
    padding-bottom: 5px;
}
.pb_10 {
    padding-bottom: 10px;
}
.pb_15 {
    padding-bottom: 15px;
}
.pb_20 {
    padding-bottom: 20px;
}
.pb_25 {
    padding-bottom: 25px;
}
.pb_30 {
    padding-bottom: 30px;
}
.pb_35 {
    padding-bottom: 35px;
}
.pb_40 {
    padding-bottom: 40px;
}
.pb_45 {
    padding-bottom: 45px;
}
.pb_50 {
    padding-bottom: 50px;
}
.pb_55 {
    padding-bottom: 55px;
}
.pb_60 {
    padding-bottom: 60px;
}
.pb_65 {
    padding-bottom: 65px;
}
.pb_70 {
    padding-bottom: 70px;
}
.pb_75 {
    padding-bottom: 75px;
}
.pb_80 {
    padding-bottom: 80px;
}
.pb_85 {
    padding-bottom: 85px;
}
.pb_90 {
    padding-bottom: 90px;
}
.pb_95 {
    padding-bottom: 95px;
}
.pb_100 {
    padding-bottom: 100px;
}
.pr_5 {
    padding-right: 5px;
}
.pr_10 {
    padding-right: 10px;
}
.pr_15 {
    padding-right: 15px;
}
.pr_20 {
    padding-right: 20px;
}
.pr_25 {
    padding-right: 25px;
}
.pr_30 {
    padding-right: 30px;
}
.pr_35 {
    padding-right: 35px;
}
.pr_40 {
    padding-right: 40px;
}
.pr_45 {
    padding-right: 45px;
}
.pr_50 {
    padding-right: 50px;
}
.pr_55 {
    padding-right: 55px;
}
.pr_60 {
    padding-right: 60px;
}
.pr_65 {
    padding-right: 65px;
}
.pr_70 {
    padding-right: 70px;
}
.pr_75 {
    padding-right: 75px;
}
.pr_80 {
    padding-right: 80px;
}
.pr_85 {
    padding-right: 85px;
}
.pr_90 {
    padding-right: 90px;
}
.pr_95 {
    padding-right: 95px;
}
.pr_100 {
    padding-right: 100px;
}
.pl_5 {
    padding-left: 5px;
}
.pl_10 {
    padding-left: 10px;
}
.pl_15 {
    padding-left: 15px;
}
.pl_20 {
    padding-left: 20px;
}
.pl_25 {
    padding-left: 25px;
}
.pl_30 {
    padding-left: 30px;
}
.pl_35 {
    padding-left: 35px;
}
.pl_40 {
    padding-left: 40px;
}
.pl_45 {
    padding-left: 45px;
}
.pl_50 {
    padding-left: 50px;
}
.pl_55 {
    padding-left: 55px;
}
.pl_60 {
    padding-left: 60px;
}
.pl_65 {
    padding-left: 65px;
}
.pl_70 {
    padding-left: 70px;
}
.pl_75 {
    padding-left: 75px;
}
.pl_80 {
    padding-left: 80px;
}
.pl_85 {
    padding-left: 85px;
}
.pl_90 {
    padding-left: 90px;
}
.pl_95 {
    padding-left: 95px;
}
.pl_100 {
    padding-left: 100px;
}
