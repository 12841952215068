/* adapted from: https://loading.io/css/ */

$dot-size: 13px;
$dot-spacing: 8px;
$duration: 0.6s;

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate($dot-spacing * 3, 0);
    }
}

@keyframes lds-ellipsis2-short {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate($dot-spacing * 1, 0);
    }
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;

    span {
        position: absolute;
        top: 33px;
        width: $dot-size;
        height: $dot-size;
        border-radius: 50%;
        background: white;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);

        &:nth-child(1) {
            left: $dot-spacing;
            animation: lds-ellipsis1 $duration infinite;
        }

        &:nth-child(2) {
            left: $dot-spacing;
            animation: lds-ellipsis2 $duration infinite;
        }

        &:nth-child(3) {
            left: $dot-spacing * 4;
            animation: lds-ellipsis2 $duration infinite;
        }

        &:nth-child(4) {
            left: $dot-spacing * 7;
            animation: lds-ellipsis3 $duration infinite;
        }
    }

    // a lot smaller within .value-loading

    .value-loading &,
    .button &,
    .btn & {
        $dot-size: 4px;
        $dot-spacing: 3px;

        height: 11px;
        width: 30px;
        left: 3px;

        span {
            width: $dot-size;
            height: $dot-size;
            top: 4px;

            &:nth-child(1) {
                left: $dot-spacing;
            }

            &:nth-child(2) {
                left: $dot-spacing;
                animation: lds-ellipsis2-short $duration infinite;
            }

            &:nth-child(3) {
                left: $dot-spacing * 4;
                animation: lds-ellipsis2-short $duration infinite;
            }

            &:nth-child(4) {
                left: $dot-spacing * 4;
            }
        }
    }

    .button &,
    .btn & {
        margin-left: 12px;
    }
}
