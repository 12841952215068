/* @media (min-width: 1200px) {
    .container {
        max-width: 1280px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .container {
        max-width: 992px;
    }
} */


@media (min-width: 1600px) {
    .container-xl, .container-lg, .container-md, .container-sm, .container {
        max-width: 1400px;
    }
}

/* hero styles */

@media (max-width: 1599.98px) {
    /* .hero-area .hero-text h2 {
    } */
    .hero-area .hero-text p {
        font-size: 16px;
        line-height: 28px;
    }
    .hero-area .hero-text a.button {
        padding: 10px 14px;
    }
    .hero-area .hero-button-bar .app-store {
        height: 42px;
    }
    .hero-area .hero-button-bar .google-play {
        height: 62px;
    }
    .hero-area .hero-button-bar .coming-soon {
        padding: 8px 9px;
        font-size: 11px;
        right: 0;
        top: -15px;
    }
}

@media (max-width: 1199.98px) {
    .hero-area .hero-text h2 {
        font-size: 78px;
    }
    .hero-area .hero-text p {
        font-size: 16px;
        line-height: 28px;
    }
    .hero-area .hero-text a.button {
        font-size: 13px;
        padding: 8px 14px;
    }
    .hero-area .hero-button-bar .app-store {
        height: 34px;
    }
    .hero-area .hero-button-bar .google-play {
        height: 51px;
    }
    .hero-area .hero-button-bar .coming-soon {
        right: 0;
        top: -19px;
    }
}

@media (max-width: 991px) {
    .hero-area .hero-image {
        display: none;
    }
    .hero-area .hero-text h2 {
        font-size: 78px;
    }
    .hero-area .hero-text h2::before {
        top: -35px;
    }
    .hero-area .hero-text p {
        font-size: 20px;
        line-height: 36px;
    }
    .hero-area .hero-text a.button {
        font-size: 16px;
        padding: 10px 22px;
    }
    .hero-area .hero-button-bar .app-store {
        height: 42px;
    }
    .hero-area .hero-button-bar .google-play {
        height: 62px;
    }
    .hero-area .hero-button-bar .coming-soon {
        right: 0;
        top: -15px;
    }
}

@media (max-width: 767.99px) {
    .hero-area .hero-text h2 {
        font-size: 78px;
    }
    .hero-area .hero-text p {
        font-size: 16px;
        line-height: 28px;
    }
    .hero-area .hero-text a.button {
        font-size: 13px;
        padding: 8px 14px;
    }
    .hero-area .hero-button-bar .app-store {
        height: 34px;
    }
    .hero-area .hero-button-bar .google-play {
        height: 51px;
    }
    .hero-area .hero-button-bar .coming-soon {
        right: 0;
        top: -19px;
    }
}

@media (max-width: 575.98px) {
    .hero-area .hero-text h2 {
        font-size: 70px;
    }
    .hero-area .hero-text h2::before {
        width: 230px;
    }
    .hero-area .hero-text p {
        font-size: 16px;
        line-height: 28px;
    }
    .hero-area .hero-text a.button {
        font-size: 13px;
        padding: 8px 14px;
    }
    .hero-area .hero-button-bar .app-store {
        height: 34px;
    }
    .hero-area .hero-button-bar .google-play {
        height: 51px;
    }
    .hero-area .hero-button-bar .coming-soon {
        right: 0;
        top: -19px;
    }
}

@media (max-width: 460px) {
    .hero-area .hero-text h2 {
        font-size: 48px;
        line-height: 70px;
    }
    .hero-area .hero-text h2::before {
        width: 230px;
    }
    .hero-area .hero-text p {
        font-size: 13px;
        line-height: 24px;
        margin-top: 12px;
    }
    .hero-area .hero-text a.button {
        font-size: 13px;
        padding: 8px 14px;
    }
    .hero-area .hero-button-bar {
        flex-wrap: wrap;
        width: 240px;
        margin: 40px auto;
    }
    .hero-area .hero-button-bar .spacer-mobile {
        display: block;
        flex: auto;
    }
    .hero-area .hero-button-bar .app-store {
        height: 34px;
        margin-top: 50px;
    }
    .hero-area .hero-button-bar .google-play {
        height: 51px;
        margin-top: 50px;
        margin-right: -9px;
    }
    .hero-area .hero-button-bar .coming-soon {
        right: -12px;
        top: 71px;
        padding: 5px 9px;
    }
}

/* end hero styles */



@media (max-width: 1399.98px) {
    .header-btn-box .btn-1 {
        padding: 8px 10px;
    }
    .header-btn-box .btn-2 span {
        padding: 8px 10px;
    }
    .token-area .token-box h3 {
        font-size: 22px;
    }
    .token-area .token-box p br {
        display: none;
    }
    .token-counter-box .sale-timer {
        padding: 40px 25px;
    }
    .contact-shape-box .shape.shape3 {
        left: -85px;
    }
    .contact-shape-box .shape.shape10 {
        right: -95px;
    }
    .contact-shape-box .shape.shape8 {
        right: -77px;
    }
    .edtoken-area .img-area {
        width: 480px;
        height: 480px;
    }
    .edtoken-area .img-area::before {
        width: 600px;
        height: 600px;
        top: -59px;
    }
    .edtoken-area .img-area::after {
        width: 700px;
        height: 700px;
        top: -109px;
    }
    .edtoken-area .edtoken-text-box.box-1 .item .item-icon.item1 {
        right: -139px;
    }
    .edtoken-area .edtoken-text-box.box-1 .item .item-icon.item2 {
        right: -81px;
    }
    .edtoken-area .edtoken-text-box.box-1 .item .item-icon.item3 {
        right: -108px;
    }
    .edtoken-area .edtoken-text-box.box-2 .item .item-icon.item4 {
        left: -143px;
    }
    .edtoken-area .edtoken-text-box.box-2 .item .item-icon.item5 {
        left: -86px;
    }
    .edtoken-area .edtoken-text-box.box-2 .item .item-icon.item6 {
        left: -106px;
    }
    .edtoken-area .edtoken-text-box .item {
        margin-right: 35px;
    }
    .edtoken-area .edtoken-text-box .item.left-align {
        margin-left: 35px;
    }
    .edtoken-area .edtoken-text-box.box-1 .item.item-mar {
        margin-right: 70px;
    }
    .edtoken-area .edtoken-text-box .item.left-align.item2 {
        margin-left: 75px;
    }
    .road-map-area .contact-shape-box .shape.shape5 {
        right: -90px;
    }
    .road-map-area .line-img-area {
        top: 200px;
        left: 201px;
    }
    .road-map-area .map-box.check-margin {
        margin-left: 60px;
    }
}

@media (max-width: 1279px) {
    .edtoken-area .edtoken-text-box .item p br {
        display: none;
    }
    .edtoken-area .edtoken-text-box .item {
        margin-right: 48px;
    }
    .edtoken-area .edtoken-text-box .item.left-align {
        margin-left: 48px;
    }
    .edtoken-area .edtoken-text-box.box-1 .item.item-mar {
        margin-right: 105px;
    }
    .edtoken-area .edtoken-text-box .item.left-align.item2 {
        margin-left: 108px;
    }
    .edtoken-area .circle-area {
        display: none;
    }
    .clender-box::before {
        display: none;
    }
    .edtoken-area .edtoken-text-box .item.left-align,
    .edtoken-area .edtoken-text-box .item.left-align.item2 {
        margin-left: 0;
    }
    .edtoken-area .edtoken-text-box .item {
        margin-right: 0;
        text-align: left;
    }
    .edtoken-area .item .item-icon {
        position: relative;
        left: 0 !important;
    }
    .edtoken-area .edtoken-text-box .item .item-icon {
        margin-bottom: 10px;
    }
    .edtoken-area .edtoken-text-box .item.mt_70 {
        margin-top: 30px !important;
    }
}

@media (max-width: 1199.98px) {
    .clender-wrapper::before {
        width: 100%;
    }
    .header-btn-box .button {
        font-size: 13px;
    }
    header .menu li a {
        margin-right: 14px;
        font-size: 13px;
    }
    .header-btn-box .btn-1 {
        padding: 8px 8px;
    }
    .header-btn-box .btn-2 span {
        padding: 8px 8px;
    }
    .funds-area .chart-area {
        padding: 25px;
    }
    .token-box-wrapper .item > p {
        font-size: 14px;
    }
    .token-box-wrapper .item {
        padding: 15px;
    }
    .token-area .app-carousel {
        margin-bottom: 50px;
    }
    .faq-area .tab-area .accordion .card .card-header {
        font-size: 18px;
    }
    .contact-option-wrapper .item a {
        font-size: 16px;
    }
    .section-title h2 {
        font-size: 36px;
    }
    .clender-wrapper .clender-box::before {
        left: -23px;
    }
    .road-map-area .map-box .text-box p {
        font-size: 12px;
    }
    .road-map-area .row-top {
        margin-top: 11px;
    }
    .road-map-area .row-top.top-2 {
        margin-top: -12px;
    }
    .road-map-area .line-img-area img {
        width: 88%;
    }
    .road-map-area .line-img-area {
        top: 196px;
        left: 163px;
    }
    .road-map-area .contact-shape-box .shape.shape4,
    .road-map-area .contact-shape-box .shape.shape5 {
        top: 755px;
    }
    .road-map-area .contact-shape-box .shape.shape1 {
        left: -127px;
    }
    .road-map-area .contact-shape-box .shape.shape3 {
        left: -122px;
    }
    .contact-area .contact-shape-box .shape.shape1 {
        left: -88px;
    }
    .hero-area {
        padding: 130px 0 90px;
    }
}

@media (max-width: 991px) {
    .contact-shape-box {
        display: none;
    }
    .clender-wrapper {
        flex-wrap: wrap;
        padding-top: 40px;
    }
    .clender-wrapper .clender-box {
        max-width: 48.3%;
        flex: 0 0 50%;
        margin-top: 15px;
    }
    .sale-timer {
        max-width: 100%;
    }
    .hero-area {
        padding: 95px 0 95px;
    }
    header .menu {
        display: none;
    }
    .header-btn-box {
        display: none;
    }
    .header-btn-box.off-canvas {
        display: block;
        text-align: center;
        margin-top: 30px;
    }
    .hamburger-menu {
        display: block;
        position: absolute;
        top: 6px;
        right: 15px;
    }
    .mobile-menu-wrapper {
        display: block;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background-color: #000;
        position: fixed;
        z-index: -1;
        left: -100%;
        transition: 0.3s;
    }
    .mobile-menu-wrapper.show-menu {
        left: 0;
        transition: 0.3s;
    }
    .mobile-menu-wrapper ul {
        padding-top: 130px;
    }
    .mobile-menu-wrapper ul li {
        text-align: center;
        padding: 5px 0;
    }
    .section-title {
        margin-bottom: 20px !important;
    }
    .section-title h2 {
        font-size: 30px !important;
    }
    .section-title p {
        font-size: 16px;
    }
    .section-title p br {
        display: none;
    }
    .token-area .token-box h3 {
        font-size: 18px;
    }
    .token-area .token-box p {
        font-style: 15px;
    }
    .funds-area .chart-area .parcent-area {
        display: block !important;
    }
    .funds-area .chart-area .parcent-area .area-2 .p4,
    .funds-area .chart-area .parcent-area .area-2 .p9 {
        margin-top: 30px;
    }
    .token-counter-box .sale-timer {
        max-width: 100%;
    }
    .team-area .team-card .info-area h3 {
        font-size: 18px;
    }
    .team-area .team-card .info-area p {
        font-size: 16px;
    }
    .faq-area .tab-area .accordion .card .card-header {
        line-height: 20px;
    }
    .contact-option-wrapper {
        padding: 10px;
    }
    .contact-option-wrapper .item .icon-area {
        height: 35px;
        width: 35px;
        line-height: 35px;
        margin-right: 8px;
    }
    .contact-option-wrapper .item a {
        font-size: 13px;
    }
    .contact-area .contact-shape-box {
        display: none;
    }
    footer .footer-box h2 {
        font-size: 30px;
    }
    footer .footer-box p br {
        display: none;
    }
    .road-map-area .map-box {
        max-width: 100%;
    }
    .road-map-area .line-img-area,
    .road-map-area .map-box .radio-box {
        display: none;
    }
    .road-map-area .map-box .text-box {
        margin-top: 17px;
    }
    .road-map-area .map-box.check-margin {
        margin-left: 0px;
    }
    .road-map-area .row-top {
        flex-direction: row;
    }
    .road-map-area .row-top,
    .road-map-area .row-top.top-2 {
        margin-top: 0;
    }
    .accordion .col-mar-rem {
        margin-top: 0 !important;
    }
    .contact-area,
    .faq-area,
    .team-area,
    .document-area,
    .road-map-area,
    .sale-area,
    .funds-area,
    .token-area,
    .edtoken-area {
        padding: 70px 0;
    }
    .edtoken-area .edtoken-text-box.box-1 .item.item-mar {
        margin-right: 0 !important;
    }
    .contact-area form {
        margin-top: 30px;
    }
    footer {
        padding-top: 70px;
    }
    .contact-area form {
        padding: 0 30px 30px 30px;
    }
}

@media (max-width: 767.99px) {
    .contact-option-wrapper .item a {
        font-size: 15px;
    }
    .team-area .team-card .img-area img {
        height: 140px;
        width: 140px;
    }
    .partners-area .partner {
        margin-bottom: 15px;
    }
    .contact-option-wrapper {
        display: block;
    }
    .contact-option-wrapper .mt {
        margin-top: 10px;
    }
    .contact-area form {
        padding: 0 30px 30px 30px;
    }
}

@media (max-width: 575.98px) {
    .section-title h2 {
        font-size: 22px !important;
    }
    .token-counter-box .sale-timer {
        padding: 15px;
    }
    .token-counter-box .sale-timer .timer div {
        margin-right: 20px;
    }
    .team-area .investor-board h2 {
        font-size: 25px;
    }
    .token-area .app-carousel {
        padding: 0 50px;
    }
    .faq-area .tab-area .nav {
        max-width: 441px;
    }
    .faq-area .tab-area .nav .nav-item.nav-link {
        font-size: 12px;
        padding: 10px;
    }
    .faq-area .tab-area .accordion .card .card-header {
        font-size: 16px;
    }
    .faq-area .tab-area .accordion .card .card-body {
        font-size: 14px;
    }
    .contact-area form {
        padding: 15px;
    }
    .contact-area form .field-first {
        margin-top: 0 !important;
    }
    footer .footer-box h2 {
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 35px;
    }
    footer .footer-box .sub-box {
        display: block;
    }
    footer .footer-box .btn-sub {
        width: 100%;
    }
    footer .footer-box .sub-box .button {
        width: 100%;
        margin-left: 0;
        margin-top: 20px;
    }
    footer .footer-box .footer-icon {
        margin-bottom: 42px;
    }
    footer {
        padding-top: 55px;
    }
    footer .footer-box .sub-box {
        margin-bottom: 35px;
    }
    footer .footer-box p {
        margin-bottom: 40px;
    }
    .contact-area,
    .faq-area,
    .team-area,
    .document-area,
    .road-map-area,
    .sale-area,
    .funds-area,
    .token-area,
    .edtoken-area {
        padding: 55px 0;
    }
    .sale-timer .timer div {
        margin-right: 20px;
    }
    .sale-timer {
        padding: 15px;
    }
    .home-range-area {
        margin-top: 40px;
    }
    .home-range-area .label-box span {
        font-size: 14px;
    }
    .home-icon-area {
        padding: 20px 0;
    }
    .home-icon-area::before,
    .home-icon-area::after {
        width: 100%;
    }
    .sale-box-wrapper {
        margin-bottom: 38px;
    }
    .edtoken-area .edtoken-text-box .item h3 {
        font-size: 18px;
    }
    .token-box-wrapper .item > span,
    .document-area .document-card .text-box h3 {
        font-size: 18px;
    }
    .clender-wrapper {
        padding-top: 23px;
    }
    .section-title p {
        font-size: 15px;
        line-height: 20px;
    }
    .faq-area .tab-area .accordion .card .card-header {
        font-size: 15px;
    }
    footer .footer-box .sub-box .input-box input {
        font-size: 14px;
    }
    .button,
    footer .copyright,
    footer .footer-box p,
    .contact-area form input,
    .contact-area form textarea {
        font-size: 15px !important;
    }
    .hero-area .hero-text a.button {
        font-size: 13px !important;
    }
}

@media (max-width: 359px) {
    .faq-area .tab-area .nav .nav-item.nav-link {
        padding: 9px;
        font-size: 11px;
    }
    footer .footer-box .footer-icon a {
        margin-right: 17px;
    }
    footer .footer-box h2 {
        font-size: 19px;
    }
    .faq-area .tab-area .nav {
        max-width: 100%;
    }
    .clender-wrapper .clender-box {
        margin-top: 9px;
    }
}
