/*!Ion.RangeSlider, 2.3.1, © Denis Ineshin, 2010 - 2019, IonDen.com, Build date: 2019-12-19 16:51:02*/
.irs {
    position: relative;
    display: block;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 12px;
    font-family: Arial, sans-serif;
}
.irs-line {
    position: relative;
    display: block;
    overflow: hidden;
    outline: none !important;
}
.irs-bar {
    position: absolute;
    display: block;
    left: 0;
    width: 0;
}
.irs-shadow {
    position: absolute;
    display: none;
    left: 0;
    width: 0;
}
.irs-handle {
    position: absolute;
    display: block;
    box-sizing: border-box;
    cursor: default;
    z-index: 1;
}
.irs-handle.type_last {
    z-index: 2;
}
.irs-min,
.irs-max {
    position: absolute;
    display: none;
    cursor: default;
}
.irs-min {
    left: 0;
}
.irs-max {
    right: 0;
}
.irs-from,
.irs-to,
.irs-single {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    cursor: default;
    white-space: nowrap;
}
.irs-grid {
    position: absolute;
    display: none;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20px;
}
.irs-with-grid .irs-grid {
    display: block;
}
.irs-grid-pol {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 8px;
    background: #000;
}
.irs-grid-pol.small {
    height: 4px;
}
.irs-grid-text {
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    text-align: center;
    font-size: 9px;
    line-height: 9px;
    padding: 0 3px;
    color: #000;
}
.irs-disable-mask {
    position: absolute;
    display: block;
    top: 0;
    left: -1%;
    width: 102%;
    height: 100%;
    cursor: default;
    background: rgba(0, 0, 0, 0);
    z-index: 2;
}
.lt-ie9 .irs-disable-mask {
    background: #000;
    filter: alpha(opacity=0);
    cursor: not-allowed;
}
.irs-disabled {
    opacity: 0.4;
}
.irs-hidden-input {
    position: absolute !important;
    display: block !important;
    top: 0 !important;
    left: 0 !important;
    width: 0 !important;
    height: 0 !important;
    font-size: 0 !important;
    line-height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: hidden;
    outline: none !important;
    z-index: -9999 !important;
    background: none !important;
    border-style: solid !important;
    border-color: transparent !important;
}
.irs--round {
    height: 50px;
}
.irs--round.irs-with-grid {
    height: 65px;
}
.irs--round .irs-line {
    top: 36px;
    height: 10px;
    background-color: rgba(33, 78, 53, 1);
    border-radius: 4px;
}
.irs--round .irs-bar {
    top: 36px;
    height: 10px;
    background: linear-gradient(105.4deg, #015e53 -0.93%, #01b36d 78.4%);
    transition: width 250ms ease-in-out;
}
.irs--round .irs-bar--single {
    border-radius: 4px 0 0 4px;
}
.irs--round .irs-shadow {
    height: 4px;
    bottom: 21px;
    background-color: rgba(222, 228, 236, 0.5);
}
.irs--round .irs-handle {
    top: 33px;
    width: 14px;
    height: 14px;
    border: 2px solid #fff;
    background-color: rgba(1, 179, 109, 1);
    border-radius: 24px;
    box-shadow: 0 1px 3px rgba(0, 0, 255, 0.3);
    transition: left 250ms ease-in-out;
}
/* .irs--round .irs-handle.state_hover,
.irs--round .irs-handle:hover {
    background-color: ??????;
} */
.irs--round .irs-min,
.irs--round .irs-max {
    color: #333;
    font-size: 14px;
    line-height: 1;
    top: 0;
    padding: 3px 5px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}
.irs--round .irs-from,
.irs--round .irs-to,
.irs--round .irs-single {
    font-size: 14px;
    line-height: 1;
    text-shadow: none;
    padding: 3px 5px;
    background-color: #006cfa;
    color: white;
    display: none;
    border-radius: 4px;
}
.irs--round .irs-from:before,
.irs--round .irs-to:before,
.irs--round .irs-single:before {
    position: absolute;
    display: block;
    content: '';
    bottom: -6px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -3px;
    overflow: hidden;
    border: 3px solid transparent;
    border-top-color: #006cfa;
}
.irs--round .irs-grid {
    height: 25px;
}
.irs--round .irs-grid-pol {
    background-color: #dedede;
}
.irs--round .irs-grid-text {
    color: silver;
    font-size: 13px;
}
